/*------------------------------------*\

          ____           _  __    
         / __"| u       |"|/ /    
        <\___ \/        | ' /     
         u___) |      U/| . \\u   
         |____/>>       |_|\_\    
          )(  (__)    ,-,>> \\,-. 
         (__)          \.)   (_/  


           CODE BY SIMON KNEBL

\*------------------------------------*/

/*------------------------------------*\
  MODULES
\*------------------------------------*/

// @import 'normalize.css';
@import 'poly-fluid-sizing';
@import 'include-media';

/*------------------------------------*\
  FONT FILES & SPECIFICATION
\*------------------------------------*/

@font-face {
    font-family: 'Autopia';
    src: url('../fonts/Autopia-Regular.woff2') format('woff2'),
        url('../fonts/Autopia-Regular.woff') format('woff');

    font-style: normal;
    font-weight: 300;
}

html {
    @include poly-fluid-sizing('font-size',
        (320px: 20px, 768px:20px, 1024px:28px, 1400px:28px));
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

/*------------------------------------*\
  VARIABLES
\*------------------------------------*/

$margin:0.8rem;
$black: rgb(15, 15, 15);
$white: rgb(210, 210, 210);
$spacing:0.05em;

/*------------------------------------*\
  MIXINS
\*------------------------------------*/

@mixin shadow($size, $color) {
    box-shadow: 0 0 $size 0.5*$size $color;
}

/*------------------------------------*\
  BASE
\*------------------------------------*/

html,
body {
    font-family: 'Autopia';
    margin:0;
    color: $white;
    background-color: $black;
    transition: filter 1.7s cubic-bezier(0.64, 0.2, 0.16, 1.07);
}

body.still {
    min-width:100vw;
    min-height:100vh;
    overflow: hidden;
}

body.flow {
    filter: blur(30vw);
    pointer-events: none;

    @include media("<tablet", "portrait") {
        filter: blur(30vh);
    }

}
body.transmitting,
body.transmitting *{
    cursor: wait!important;
}

* {
    box-sizing: border-box;
}

.page-active {
    display: block !important;
}

a {
    color: $white;
}

a:visited {
    color: $white;
}

/*------------------------------------*\
  SVG
\*------------------------------------*/

.fill-white svg path,
svg.fill-white path,
path.fill-white {
    fill: $white;
}

/*------------------------------------*\
  TITLE
\*------------------------------------*/

.title {
    position: fixed;
    z-index: 99;
}

.title svg {
    position: relative;
    display: block;
    width: auto;
    height: 1em;
    font-size: 1em;
}

.sl-s {
    padding-left: 0.5*$spacing;
}

.sl-m {
    padding-left: $spacing;
}

.sl-l {
    padding-left: 1.5*$spacing;
}

.sr-s {
    padding-right: 0.5*$spacing;
}

.sr-m {
    padding-right: $spacing;
}

.sr-l {
    padding-left: 1.5*$spacing;
}

.title-neurovague {
    width: auto;
    height: auto;
    left: 50vw;
    top: $margin;
    transform: translateX(-50%);
    font-size: 4rem;
    cursor: pointer;

    @include media("<tablet") {
        width: 100%;

        @include media("landscape"){
            width:auto;
        }

    }

    @media (max-height:19rem) {

        left: $margin;
        font-size: 2.5rem;
        transform: none;

    }
}

.title-images {
    width: auto;
    height: auto;
    font-size: 2rem;
    cursor: pointer;

    @include media("<tablet") {
        width: 100%;
        left: 0;
        @include media("landscape"){
            width:auto;
        }
    }

    @media (max-height: 19rem) {
        font-size: 2.5rem;
    }
}

#landing .title-images {
    left: 50vw;
    transform: translateX(-50%);
    bottom: 1.2*$margin;
    bottom: calc(1.2*#{$margin} + env(safe-area-inset-bottom));

    @media (max-height: 19rem) {

        left: auto;
        right: $margin;
        top: $margin;
        transform: none;

    }
}

#images .title-images {
    left: 50vw;
    transform: translateX(-50%);
    top: $margin;
    top: calc(#{$margin} + env(safe-area-inset-top));
    position: fixed;
    // z-index: 99;
    mix-blend-mode: difference;
}

#about .title-neurovague {
    left: 50vw;
    transform: translateX(-50%);
    top: $margin;
    top: calc(#{$margin} + env(safe-area-inset-top));
    position: fixed;
    mix-blend-mode: difference;
    z-index: 99;
    font-size: 3rem;

    @media (max-height: 19rem) {
        font-size: 3.5rem;
    }
}

.title-group {
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
    padding: 0.05em 0;
    justify-content: center;
}

.title-group.glyphs-smooth svg {
    transition: height 250ms ease;
}

/*------------------------------------*\
  PAGE CHAPTERS
\*------------------------------------*/

#landing,
#about,
#images,
.overlay {
    position: absolute;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    top: 0;
    left: 0;
    display: none;
}

#images,
#about {
    overflow: scroll;
}
#about{
    padding-bottom: 3rem;
}

#images,
.overlay {
    transition: filter 800ms cubic-bezier(0.64, 0.2, 0.16, 1.07),
        opacity 600ms ease;
}

#images.discreet {
    filter: blur(1rem);
}

.overlay {
    display: block;
    filter: blur(0.5rem);
    opacity: 0;
    z-index: 99;
    pointer-events: none;
}

.overlay-active {
    filter: none;
    opacity: 1;
    pointer-events: all;
}

/*------------------------------------*\
  CONTENT
\*------------------------------------*/

#proposition {
    position: fixed;
    font-size: 1.5rem;
    height: 1em;
    width: 100vw;
    left: 0vw;
    top: 50vh;
    padding: 1em 0;
    overflow: hidden;

    @media (max-height: 19rem) {
        top: unset;
        bottom: 2*$margin;
    }
}

#proposition .margin-left,
.margin-right {
    position: absolute;
    width: auto;
    height: 3rem;
    left: 0;
    top: 0;
    mix-blend-mode: difference;
    z-index: 1;
}

#proposition .margin-left svg,
#proposition .margin-right svg {
    width: auto;
    height: 100%;
}

#proposition .margin-left path,
#proposition .margin-right path {
    fill: $white;
}

#proposition .margin-right {
    right: 0;
    left: unset;
}

#proposition .text {
    position: absolute;
    width: auto;
    height: auto;
    left: 0;
    top: 0;
    padding: 0.5em 0;
    white-space: nowrap;
}

#proposition .text p {
    display: inline-block;
    margin: 0;
    padding-right: 100vw;
}

/*------------------------------------*\
  SELECTION
\*------------------------------------*/

#selections {
    position: relative;
    width: fit-content;
    max-width: 30rem;
    margin: 0 auto;
    display: flex;
    padding: 2rem 0;
    padding-bottom: 7rem;
    top: 7rem;

    @media (max-height: 19rem) {
        top: 8rem;
    }

    @include media("<tablet") {

        max-width: 85vw;
        max-width:calc(100vw - 1.5*#{$margin});


    }

    // flex-direction: column;
    flex-wrap: wrap;
}

#ui {
    position: fixed;
    padding: 0.5rem;
    bottom: $margin;
    width: fit-content;
    bottom:2*$margin;
    bottom: calc(2*#{$margin} + env(safe-area-inset-bottom));
    left: 50vw;
    transform: translateX(-50%);
    // mix-blend-mode: difference;
}


.wrap {
    position: relative;
    text-align: center;
    font-size: 1.6rem;
    margin: 0 auto;
    overflow: visible;
}

.buttonwrap {
    position: relative;
    display: flex;
    align-items: flex-start;
}
.buttonwrap div{
    margin:0 1rem;
    white-space: nowrap;
}

.select {
    cursor: pointer;
    overflow: visible;
    text-decoration: underline;
    text-decoration-thickness: 0.05em;
}
.select:hover{
    animation:vague 6s ease infinite alternate;
}

$vaguestart: 0.15rem;
$vagueend: 0.18rem;

@keyframes vague {
    0%{
        filter:blur(0rem);
    }
    10%{
        filter:blur($vaguestart);
    }
    20%{
        filter:blur($vagueend);
    }
    40%{
        filter:blur($vaguestart);
    }
    50%{
        filter:blur($vagueend);
    }
    60%{
        filter:blur($vaguestart);
    }
    80%{
        filter:blur($vagueend);
    }
    90%{
        filter:blur($vaguestart);
    }
    100%{
        filter:blur($vagueend);
    }
}


.txt-hide {
    display: none;
}

#ui .addimage,
#ui .getimage {
    padding: 0.4rem 0.7rem;
    border-radius: 1rem;
    background-color: $white;
    color: black;
    text-decoration: none;
    position: relative;
    cursor: pointer;
    border: 0.06em solid $white;
    transition: background-color 400ms ease,
                color 400ms ease,
                border 400ms ease;
}

#ui .addimage.full,
#ui .getimage.transmitting{
    color: $white;
    background-color: transparent;
    border: 0.06em solid $white;
    cursor: default;
}

.and-divider {
    position: relative;
    margin: 1rem 0;
    width: 100%;
    text-align: center;
    font-size: 1rem;
}

/*------------------------------------*\
  SELECTION OVERLAY
\*------------------------------------*/

.overlay .option-wrap {
    position: relative;
    max-width: 70vw;
    height: 100vh;
    margin: 0 auto;
    font-size: 1.6rem;
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
    flex-wrap: wrap;
}

.overlay .option {
    position: relative;
    padding: 0.25em 0.6em;
    border-radius: 2rem;
    color: $black;
    background-color: $white;
    border: 0.03em solid $white;
    cursor: pointer;
    text-align: center;
    transition: background-color 100ms ease,
        color 100ms ease;
    margin:0.25em;
}

.overlay .option:hover {
    background-color: rgba(0, 0, 0, 0.01);
    color: $white;
}

.overlay .option.remove{
    position:absolute;
    bottom:3*$margin;
    bottom: calc(3*#{$margin} + env(safe-area-inset-bottom));
    left:50vw;
    transform: translateX(-50%);
}


/*------------------------------------*\
  ABOUT
\*------------------------------------*/

#about .text {
    position: relative;
    width: 30rem;
    margin: 0 auto;
    margin-top: 10rem;

    @include media("<tablet") {

        margin-top: 9rem;
        width: 100vw;
        padding: $margin;

    }

}

#about .text-colophon{
    padding-top:0;
    font-size: 0.7rem;
}

#about .external{
    position: fixed;
    width:auto;
    right:1.5*$margin;
    top: 1.5*$margin;
    top: calc(1.5*#{$margin} + env(safe-area-inset-top));
    padding-top:1.4em;
    padding-bottom:0.6em;
    color:$black;
    font-size:0.7rem;
    display:inline-block;
    cursor: pointer;

    @include media("<tablet", "portrait") {
        position: relative;
        margin:0 auto;
        right:auto;
        top:auto;
        font-size:1rem;
        left:50%;
        transform:translateX(-50%);
    }
}
#about .external:hover{
    color:$white;
}
#about .external-bg{
    position: absolute;
    width:auto;
    height:3em;
    top:0;
    left:0;
    z-index: 1;
    overflow: visible;
}
#about .external-bg svg{
    width:auto;
    height:100%;
    z-index: 1;
    display:block;
    overflow: visible;
}
#about .external-bg path{
    fill:$white;
}
#about .external:hover .external-bg path{
    fill:transparent;
    stroke:$white;
    stroke-width: 0.25em;
}

#about .external-text{
    position: relative;
    text-align: center;
    width:100%;
    z-index: 2;
    padding:0 0.55em;
}